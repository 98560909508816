const blogsLink =
  JSON.parse(localStorage.getItem("configs"))?.blogs?.config_value || ""

export const header = {
  headerLinks: [
    {
      id: 1,
      name: "Home",
      componentName: "FancyHome",
      hideForAlkuttab: false,
    },
    {
      id: 2,
      name: "private",
      componentName: "Tutors",
      hideForAlkuttab: false,
    },
    {
      id: 3,
      name: "groups",
      componentName: "KidsCoursesList",
      hideForAlkuttab: false,
    },
    {
      id: 4,
      name: "joinFreeHalaqa",
      componentName: "Webinars",
      hideForAlkuttab: false,
    },
    {
      id: 5,
      name: "blogs",
      link: blogsLink,
    },
    {
      id: 6,
      name: "About",
      subMenu: [
        {
          id: 1,
          name: "About ",
          componentName: "About",
        },
      ],
    },
  ],
  header_menu: {
    student: [
      {
        id: 1,
        name: "profile",
        componentName: "Profile",
      },
      {
        id: 2,
        name: "classes",
        componentName: "BookedClasses",
      },
      {
        id: 3,
        name: "updateSettings",
        componentName: "UpdatePassword",
      },
      {
        id: 4,
        name: "subAccounts",
        componentName: "SubAccounts",
      },
    ],
    tutor: [
      {
        id: 1,
        name: "profile",
        componentName: "Profile",
      },
      {
        id: 1,
        name: "classes",
        componentName: "CurrentClasses",
      },
      {
        id: 1,
        name: "updateSettings",
        componentName: "UpdatePassword",
      },
    ],
    operator: [
      {
        id: 1,
        name: "dashboard",
        componentName: "ApprovedTutors",
      },
    ],
    institute_admin: [
      {
        id: 1,
        name: "dashboard",
        componentName: "ApprovedTutors",
      },
    ],
    kids: [
      {
        id: 1,
        name: "classes",
        componentName: "BookedClasses",
      },
    ],
  },
}
